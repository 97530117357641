<template>
  <div class="topmiddle">
    <div class="middletitle">已合作主流媒体</div>
    <div class="buleIcon">MAINSTREAM MEDIA HAS BEEN COOPERATED</div>
    <div class="business">
      <img src="../assets/IMAGE48.png" alt="" />
      <img src="../assets/IMAGE49.png" alt="" />
      <img src="../assets/IMAGE50.png" alt="" />
      <img src="../assets/IMAGE51.png" alt="" />
      <img src="../assets/IMAGE52.png" alt="" />
      <img src="../assets/IMAGE53.png" alt="" />
    </div>
    <div class="business" style="margin-top: 20px">
      <img src="../assets/IMAGE54.png" alt="" />
      <img src="../assets/IMAGE55.png" alt="" />
      <img src="../assets/IMAGE56.png" alt="" />
      <img src="../assets/IMAGE57.png" alt="" />
      <img src="../assets/IMAGE58.png" alt="" />
      <img src="../assets/IMAGE59.png" alt="" />
    </div>
    <div class="Mytitle">
      <div class="left">
        <div class="title">关于我们</div>
        <div style="width: 445px; margin-top: 30px; font-size: 16px">
          重庆企媒号网络科技有限公司成立于2017年,是国内移动OA新媒体平台技术研发运营商,主要从事新媒体saas平台开发及运营工作.目前主流业务是发展企业客户,为企业打造专属权威新媒体官方新媒体平台;获得行业监管机构加v认证,同时基于企业官媒平台为企业提供新媒体营销推广一站式解决方案,赋能中小企业实现品牌孵化，电商孵化，网红孵化。
        </div>
        <div style="display: flex; align-items: center; margin-top: 30px">
          <div><img src="../assets/dianhua2.png" alt="" /></div>
          <div style="margin-left: 5px; font-size: 16px">400-875-1066</div>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px">
          <div><img src="../assets/dingwei.png" alt="" /></div>
          <div style="margin-left: 5px; font-size: 16px">
            重庆市江北区海尔路176号附68号17-2
          </div>
        </div>
        <div style="display: flex; margin-top: 45px">
          <div class="bottomIcon">
            <img src="../assets/kefu.png" alt="" />
            <div style="margin-top: 20px">官方客服</div>
          </div>
          <div class="bottomIcon">
            <img src="../assets/xiazai.png" alt="" />
            <div style="margin-top: 20px">品推资讯下载二维码</div>
          </div>
        </div>
      </div>
      <div>
        <img
          src="../assets/add.png"
          style="width: 550px; margin-right: 30px; margin-top: 60px"
        />
      </div>
    </div>
    <!-- <a href="https://www.iptzx.com"
      ><div style="color: #0a49ff">品推资讯-企业官媒公众开放平台</div></a
    >
    <div>渝ICP备19005330号 重庆企媒号网络科技有限公司</div> -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.topmiddle {
  height: 100%;
  margin-top: 80px;
  .middletitle {
    text-align: center;
    height: 36px;
    font-size: 36px;
    margin-top: 80px;
    color: #333333;
  }
  .buleIcon {
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 30px;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 55px;
  }
  .business {
    width: 1200px;
    margin: 0 auto;
    img {
      width: 185px;
      height: 70px;
      margin-left: 10px;
    }
  }
  .Mytitle {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 90px auto;
    .left {
      text-align: left;
      .title {
        font-size: 44px;
        color: #333333;
      }
      .bottomIcon {
        text-align: center;
        padding-right: 40px;
      }
    }
  }
}
</style>
