<template>
  <div class="mobile">
    <div class="top">
      <img src="../assets/logo.png" style="width: 24vw; height: 6vw" />
      <div style="display: flex; justify-content: center; align-items: center">
        <img
          src="../assets/zhanghu.png"
          style="width: 3.0667vw; height: 3.0667vw; margin-right: 0.2667vw"
        />
        <router-link to="/mobileHome/mobileAuthentication">
          <div style="color: #333333">官媒注册认证</div>
        </router-link>
		<!-- <router-link to="/Home/Authentication">
		  <div style="color: #333333">官媒注册认证</div>
		</router-link> -->
      </div>
    </div>
    <div style="text-align: center; margin: 0 auto">
      <a-carousel autoplay>
        <div><img src="../assets/banner10.png" style="width: 100vw" /></div>
        <div><img src="../assets/banner5.png" style="width: 100vw" /></div>
        <div><img src="../assets/banner6.png" style="width: 100vw" /></div>
        <div><img src="../assets/banner7.png" style="width: 100vw" /></div>
      </a-carousel>
    </div>
    <div class="top1">
      <div class="title">十二项权益</div>
      <div>12项免费权益赋能免费推广,赋能创业创新</div>
    </div>
    <a-carousel style="width: 92vw; margin: auto">
      <div>
        <div class="interestsBox" v-for="item in List1" :key="item.index">
          <div class="intrertsTitle">{{ item.name }}</div>
          <div style="margin-top: 2.3333vw">
            {{ item.content }}
          </div>
        </div>
      </div>
      <div>
        <div class="interestsBox" v-for="item in List2" :key="item.index">
          <div class="intrertsTitle">{{ item.name }}</div>
          <div style="margin-top: 2.3333vw">
            {{ item.content }}
          </div>
        </div>
      </div>
      <div>
        <div class="interestsBox" v-for="item in List3" :key="item.index">
          <div class="intrertsTitle">{{ item.name }}</div>
          <div style="margin-top: 2.3333vw">
            {{ item.content }}
          </div>
        </div>
      </div>
    </a-carousel>
    <div class="top1">
      <div class="title">三项有偿服务功能权益</div>
      <div class="icon">文本短信/视频短信/5G消息,精准群发营销解决方案</div>
    </div>
    <div class="equityBox">
      <div class="equityCent" v-for="(item, index) in equityList" :key="index">
        <div class="equityTop">
          <div class="equityTopTitle">{{ item.title }}</div>
          <img :src="item.img" class="equityImg" />
        </div>
        <div class="equitybot">
          {{ item.content }}
        </div>
      </div>
    </div>
    <div class="top1">
      <div class="title">经典盈利商业模式案例</div>
      <div class="icon">CLASSIC PROFITABLE BUSINESS MODEL CASE</div>
    </div>
    <a-carousel style="margin: 0 auto; text-align: center">
      <div class="caseBox" v-for="(item, index) in caseList" :key="index">
        <div class="caseTop">
          <img :src="item.img" alt="" />
          <div
            style="margin-left: 2.6667vw; color: #ffffff; margin-top: -1.3333vw"
          >
            <div class="caseTopimage">
              <div class="classContent">{{ item.title }}</div>
              <img src="../assets/gm-icon.svg" class="img1" />
              <img src="../assets/renzheng1.svg" class="img2" />
            </div>
            <div
              style="
                font-size: 2.6667vw;
                text-align: left;
                margin-top: -1.3333vw;
              "
            >
              {{ item.concent }}
            </div>
          </div>
        </div>
        <div class="caseMiddle" v-html="item.rightconcent"></div>
        <div
          style="
            width: 84vw;
            height: 0.2667vw;
            background: #f0f0f0;
            margin: 5.3333vw auto;
          "
        ></div>
        <div class="caseBottom">
          <div>
            <div>
              <span>{{ item.Franchise }}</span
              >元
            </div>
            <div>加盟费</div>
          </div>
          <div>
            <div style="font-weight: bold; font-size: 5.3333vw">
              {{ item.proportion }}
            </div>
            <div>分佣比例</div>
          </div>
          <div>
            <div>
              <span>{{ item.Franchisee }}</span
              >家
            </div>
            <div>加盟商</div>
          </div>
          <div>
            <div>
              <span>{{ item.profit }}</span
              >元+
            </div>
            <div>营收</div>
          </div>
        </div>
      </div>
    </a-carousel>
    <div class="top1">
      <div class="title">互联网+新媒体4.0时代发展历程</div>
      <div>互联网创业平台发展趋势</div>
    </div>
    <div class="box">
      <div class="trendBox" v-for="(item, index) in trendList" :key="index">
        <div class="trendTitle">{{ item.businessTitle }}</div>
        <div class="trendConcent">
          <div>
            {{ item.businessConcent }}
            <div>{{ item.concent }}</div>
            <div>{{ item.duanluo }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top1">
      <div class="title">什么是企业官媒</div>
      <div class="icon">搭建企业官方新媒体平台，</div>
      <div class="icon">实现企业全网新媒体免费营销一站式获客解决方案！</div>
    </div>
    <div class="definitionBox">
      <img src="../assets/image64.png" alt="" />
      <div>
        <div>企业官媒v唯一标识，</div>
        <div>增强品牌权威性，提升知名度。</div>
      </div>
    </div>
    <div class="definitionBox">
      <div>
        <div>建立用户信任感，官媒昵称唯一，</div>
        <div>认证企业官媒不会出现重名现象。</div>
      </div>
      <img src="../assets/image65.png" alt="" />
    </div>
    <div class="definitionBox">
      <img src="../assets/image66.png" alt="" />
      <div style="margin-left: 1.3333vw">
        <div>连接用户,</div>
        <div>搭建高效连接用户的桥梁，</div>
        <div>拥有经营用户关系以及打造私域流量阵地。</div>
      </div>
    </div>
    <div class="definitionBox">
      <div>
        <div>发动主推生意增长的引擎，</div>
        <div>实现精准营销一站式获客解决方案。</div>
      </div>
      <img src="../assets/image67.png" alt="" />
    </div>
    <div class="top1">
      <div class="title">已认证官媒</div>
      <div class="icon">CERTIFIED ENTERPRISE OFFICiIAL MEDIA</div>
    </div>
    <a-carousel style="text-align: center; margin: 0 auto">
      <div style="height: 60vw">
        <div
          style="display: flex; justify-content: center; margin-top: 9.3333vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu2.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu3.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu4.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu5.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu6.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu7.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu8.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu9.png" alt="" style="width: 44vw" />
          </div>
        </div>
      </div>
      <div>
        <div
          style="display: flex; justify-content: center; margin-top: 9.3333vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu14.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu15.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu16.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu17.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu18.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu19.png" alt="" style="width: 44vw" />
          </div>
        </div>
        <div
          style="display: flex; justify-content: center; margin-top: 2.6667vw"
        >
          <div class="imgIcon">
            <img src="../assets/zhanghu20.png" alt="" style="width: 44vw" />
          </div>
          <div class="imgIcon">
            <img src="../assets/zhanghu21.png" alt="" style="width: 44vw" />
          </div>
        </div>
      </div>
    </a-carousel>
    <router-link to="/mobileHome/mobileAuthentication">
      <div class="authentication">官媒注册认证</div>
    </router-link>
	<!-- <router-link to="/Home/Authentication">
	  <div class="authentication">官媒注册认证</div>
	</router-link> -->
    <div class="top1">
      <div class="title">共同富裕创业平台招商加盟</div>
      <div class="icon">COMMON WEALTH ENTREPRENEURSHIP PLATFORM</div>
    </div>
    <a-carousel>
      <div>
        <img
          src="../assets/iPhone10.png"
          style="text-align: center; margin: 0 auto; width: 45.3333vw"
        />
        <div class="iPhonetitle">
          <div class="title">下载品推资讯</div>
          <div>扫码下载</div>
          <div>品推资讯APP</div>
        </div>
      </div>
      <div>
        <img
          src="../assets/iPhone11.png"
          style="text-align: center; margin: 0 auto; width: 45.3333vw"
        />
        <div class="iPhonetitle">
          <div class="title">登录品推资讯</div>
          <div>登录</div>
          <div>企业官媒认证账号</div>
        </div>
      </div>
      <div>
        <img
          src="../assets/iPhone12.png"
          style="text-align: center; margin: 0 auto; width: 45.3333vw"
        />
        <div class="iPhonetitle">
          <div class="title">设置加盟费</div>
          <div>点击加盟商</div>
          <div>设置加盟费金额</div>
          <div>设置分佣比例</div>
        </div>
      </div>
      <div>
        <img
          src="../assets/iPhone13.png"
          style="text-align: center; margin: 0 auto; width: 45.3333vw"
        />
        <div class="iPhonetitle">
          <div class="title">分享意向加盟商</div>
          <div>企业官媒品牌名称</div>
          <div>分享给意向加盟商</div>
          <div>实现盈利</div>
        </div>
      </div>
    </a-carousel>
    <div class="top1">
      <div class="title">已合作主流媒体</div>
      <div class="icon"></div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 9.3333vw">
      <div class="imgIcon">
        <img src="../assets/1-1.png" style="width: 28.8vw" />
      </div>
      <router-link to="/mobileHome/mobileAuthentication"
        ><div class="imgIcon">
          <img src="../assets/2-1.png" style="width: 28.8vw" /></div
      ></router-link>
      <div class="imgIcon">
        <img src="../assets/3-1.png" style="width: 28.8vw" />
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 2.6667vw">
      <div class="imgIcon">
        <img src="../assets/4-1.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/5.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/6.png" style="width: 28.8vw" />
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 2.6667vw">
      <div class="imgIcon">
        <img src="../assets/7-1.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/8.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/9.png" style="width: 28.8vw" />
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 2.6667vw">
      <div class="imgIcon">
        <img src="../assets/10.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/11.png" style="width: 28.8vw" />
      </div>
      <div class="imgIcon">
        <img src="../assets/12.png" style="width: 28.8vw" />
      </div>
    </div>
    <div class="top1">
      <div class="title">关于我们</div>
      <div class="icon">ABOUT US</div>
      <div class="myConcent">
        重庆企媒号网络科技有限公司成立于2017年,是国内移动OA与新媒体平台技术研发运营商,主要从事新媒体saas平台开发及运营工作。目前主流业务是发展企业客户,为企业打造专属权威新媒体官方新媒体平台;获得行业监管机构加v认证,同时基于企业官媒平台为企业提供新媒体营销推广一站式解决方案,赋能中小企业实现品牌孵化,电商孵化,网红孵化。
      </div>
      <div class="concentNum">客服热线</div>
      <div style="font-size: 4vw">400-875-1066</div>
      <div class="concentNum" style="margin-top: 4vw">公司地址</div>
      <div style="padding: 0 8.8vw">重庆市江北区海尔路176号附68号17-2</div>
      <div style="display: flex; margin-top: 4vw">
        <div style="text-align: center">
          <img
            src="../assets/kefu.png"
            style="width: 22.6667vw; height: 22.6667vw"
          />
          <div style="font-size: 4vw">官方客服</div>
        </div>
        <div style="text-align: center; margin-left: 8vw">
          <img
            src="../assets/xiazai.png"
            style="width: 22.6667vw; height: 22.6667vw"
          />
          <div style="font-size: 4vw">品推资讯下载二维码</div>
        </div>
      </div>
      <div style="margin-top: 4vw">
        <img
          src="../assets/add.png"
          style="width: 91.7333vw; height: auto"
        />
      </div>
    </div>
    <div class="registerBox">
      <div class="registerTitle">核验注册流程</div>
      <div style="display: flex; flex-wrap: wrap">
        <div class="registerCentent">
          <div><img src="../assets/IMAGE60.png" /></div>
          <div class="registercenTit">官媒昵称申请</div>
          <div class="registerCen">
            一个手机号仅能申请一个企业官媒官媒昵称唯一
          </div>
        </div>
        <div class="registerCentent">
          <div><img src="../assets/IMAGE61.png" /></div>
          <div class="registercenTit">选择官媒类型</div>
          <div class="registerCen">官媒昵称确认后选择官媒认证类型</div>
        </div>
        <div class="registerCentent">
          <div><img src="../assets/IMAGE62.png" /></div>
          <div class="registercenTit">提交资质审核</div>
          <div class="registerCen">
            提交资质审核缴纳平台认证费用三个工作日内完成审核
          </div>
        </div>
        <div class="registerCentent">
          <div><img src="../assets/IMAGE63.png" /></div>
          <div class="registercenTit">享受官媒权益</div>
          <div class="registerCen">
            审核成功后会收到平台开通短信我们将会为您开通官媒专属权益
          </div>
        </div>
      </div>
    </div>
    <div style="height: 4vw">
      <a href="https://www.iptzx.com">
        <div
          style="
            text-align: center;
            font-size: 2.6667vw;
            color: #0a49ff;
            margin-top: 4vw;
          "
        >
          品推资讯-企业官媒公众开放平台
        </div>
      </a>
      <div style="text-align: center; font-size: 2.6667vw">
        渝ICP备19005330号 重庆企媒号网络科技有限公司
      </div>
      <router-link to="/mobileHome/mobileAuthentication">
      <!-- <router-link to="/Home/Authentication"> -->
        <div
          style="
            width: 100%;
            height: 10.6667vw;
            background: #0a49ff;
            font-size: 3.4667vw;
            color: #ffffff;
            text-align: center;
            line-height: 10.6667vw;
            margin-top: 5.3333vw;
            bottom: 0;
            position: fixed;
          "
        >
          官媒注册认证
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dotPosition: "bottom",
      List1: [
        {
          name: "微销通",
          content:
            "企业版分销系统,企业官媒主账号可绑定若干微信子账号,均可实现分销,分佣,客户数据,分销商级别等,一键管理。",
        },
        {
          name: "加盟商",
          content:
            "企业官媒认证持有人生成二维码,用户扫码成为加盟商,拥有加盟商身份即可持续发展加盟商,获得高额佣金奖励,还可享受免费推广宣传服务以及扶持政策",
        },
        {
          name: "全网资讯",
          content:
            "基于企业官媒SAAS系统,企业营销开放平台,一键发布,精准直达,实现软文营销全网覆盖,同步至主流媒体平台。",
        },
        {
          name: "红包推广",
          content:
            "商户原创焦点营销软文/短视频等,充值即可开启红包推广,用户充分发挥红包裂变思维,优惠促销,品牌营销宣传推广,低门槛,低成本,大众创业,万众创新,人人互动,参与共赢,真正的品牌口碑相传。",
        },
      ],
      List2: [
        {
          name: "商机发布",
          content:
            "通过企业官媒平台,一键发布,三步完成招商加盟,深度挖掘追踪意向代理,合作伙伴与加盟商。",
        },
        {
          name: "信息流广告",
          content:
            "基于品推资讯 APP 客户端,PC 版,H5版AI智能推送,通过海量媒体资源实现信息采集、深度数据挖掘和用户行为分析,为用户智能推荐个性化信息,实现精准获客一站式解决方案.",
        },
        {
          name: "视频同步助手",
          content:
            "同步助手是一款海量短视频分发平台,一键分发抖音,头条,西瓜,品推短视频,腾讯平台,可实现后台同步管理/分发,查看用户转发量,点赞量以及评论等等,同步助手资源丰富、功能齐全、安全轻便、同时支持多平台。",
        },
        {
          name: "新媒体商学院",
          content:
            "企业官媒商学院是以企业战略和人才开发为核心,根据业务需要实现业务流程以及业务知识的全方位培训,以构筑企业全员培训体系为基础,通过企业文化的导入和企业学习习惯的培育,形成企业知识管理、业务人才加工、市场竞争的智力窗口,最终成为实现企业战略规划的有力武器。",
        },
      ],
      List3: [
        {
          name: "官媒资质",
          content: "官方资质展示,增强品牌权威信, 提升知名度",
        },
        {
          name: "其他资质展示",
          content:
            "增加商标、版权、电商证、品牌证等资质证书展示,增强品牌权威信",
        },
        {
          name: "官网展示",
          content: "配置企业官网,强化主页主动运营能力。",
        },
        {
          name: "产品服务展示",
          content: "配置产品服务,方便客户更快更便捷的了解公司",
        },
      ],
      caseList: [
        {
          title: "企业官媒平台:安邦客家政",
          concent: "利用加盟商管理系统实现盈利",
          rightconcent:
            "安邦客家政是深圳的一家做家政平台的企业,注册认证企业官媒后,利用免费的<span>加盟商系统</span>他通过我们专业的运营团队免费为他的客户策划了一个营销解决方案,充分利用了加盟商系统。他设置的加盟商金额为365元,短短不到一个月时间,他就发展了300位用户成为了他的加盟商,同时加盟商推荐用户成为加盟商均可享受8折优惠家政服务,推荐者均可获得高达70%的加盟费分佣,一个月时间内实现收益<span>109500</span>元+。",
          Franchise: "365",
          proportion: "3:7",
          Franchisee: "300",
          profit: "109500",
          img: require("../assets/anli7.png"),
        },
        {
          title: "企业官媒平台:南京盐水鸭",
          concent: "利用加盟商管理系统实现盈利",
          rightconcent:
            "陈季注册认证南京盐水鸭企业官媒后,充分发挥加盟商系统的优势,我们的策划团队免费为他特别定制了一个营销策划方案,他设置的加盟费为498元整,置分佣比例30%-70%,加盟商推荐加盟商还可以享受70%高额加盟费分佣奖励,被推荐者同样仅限享受7折优惠,3个月时间发展加盟商791家,直接实现收益<span>393918</span>元+。",
          Franchise: "498",
          proportion: "3:7",
          Franchisee: "791",
          profit: "393918",
          img: require("../assets/anli8.png"),
        },
        {
          title: "企业官媒平台:养老养生",
          concent: "利用加盟商管理系统实现盈利",
          rightconcent:
            "于红梅注册认证养老养生企业官媒平台后，我们专业的运营团队发挥微销通企业分销系统的强大管理功能优势，我们的策划团队为她策划的方案是:招募合伙人。她招募了30位推广合伙人,让合伙人的微信号作为子账号绑定了自己的主账号,通过30位合伙人的行业资源、渠道,在养老养生行业推广发展加盟商,凡关注养老养生企业官媒,成为加盟商的商家/企业，均可免费享受一年的推广及广告投放服务,他设置的加盟商费为2500/年,分佣比例为5:5,3个月时间,平均每个合伙人发展9-15家不等家加盟商,总体三个月时间发展了336家,实现总收益840000元整,因为是设置的五五分佣,养老养生自己所获得的收益<span>420000</span>整。",
          Franchise: "365",
          proportion: "3:7",
          Franchisee: "300",
          profit: "420000",
          img: require("../assets/anli9.png"),
        },
      ],
      trendList: [
        {
          businessTitle: "互联网1.0时代",
          businessConcent:
            "Internet(互联网)进入中国市场真正变现是从面向B端用户开放的时候,那么面向B端用户最经典的变现模式是什么呢,根据艾瑞网官方数据报道,中国的中小企业高达5000万家,那么搭建企业网站的模式就成为了面向B端用户变现最经典的模式,这就是所谓的互联网1.0时代.",
        },
        {
          businessTitle: "互联网2.0时代",
          businessConcent:
            "根据科学技术的不断发展,建设网站仅仅是给予PC端用户的模式,移动互联网的到来彻底打破了多年来搭建网站的变现模式,从PC端的经典建站模式直接搬到了移动端,那就是APP应用程序时代,同样的网站展现模式,改变了用户的使用习惯,以及生活习惯,这就是所谓的互联网2.0时代。",
        },
        {
          businessTitle: "互联网3.0时代",
          businessConcent:
            "小程序是一种不用下载就能使用的应用,也是一项创新,经过多年的发展,已经构造了新的轻应用小程序开发环境和开发者生态,同样的移动端网站展现模式,变得更加便捷,使用起来更加流畅,开发成本更低,这就是所谓的互联网3.0时代。",
          concent:
            "总结:互联网从1.0时代演变至3.0时代,它们的共同点均基于搭建网站用于展现的经典模式,(特点:单一展现模式，被动等客上门)。",
        },
        {
          businessTitle: "互联网4.0时代",
          businessConcent:
            "从互联网1.0时代演变至程序应用2.0时代APP,升级为3.0时代的轻应用小程序.",
          duanluo:
            "在互联网3.0时代基础上演变成了新媒体(自媒体)，人人均是自媒体，商户主体也可是自媒体，企业单位也可是自媒体，每个品牌/商标均可是自媒体,这就是所谓的互联网新媒体4.0时代",
          concent: "互联网+新媒体=内容+流量(粉丝经济时代)",
        },
      ],
      equityList: [
        {
          img: require("../assets/image5.png"),
          title: "短信群发营销",
          content:
            "文本短信,具备快速触达手机用户的能力;验证码、通知、会员推广、会员营销;国内短信三网合一,5S到达,99%+触达率，简单稳定，安全易用。",
        },
        {
          img: require("../assets/image4.png"),
          title: "视频短信营销",
          content:
            "基于移动网络和互联网通信技术，集成视频、语音、 图片、文字、短链、二维码等信息通讯能力,支持1.8M信息容量、30秒视频、5帧高清图,采用移动数据网络传输方式发送后自行下载,存储在用户手机收件箱中。",
        },
        {
          img: require("../assets/image3.png"),
          title: "5G消息营销",
          content:
            "即发即现,直接展示,减少拦截,全网三网覆盖,支持视频、语音、高清图片、文字、图文动参内容、二维码、H5链接跳转、个性接入,实时访问统计。",
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.mobile {
  width: 100%;
  padding-bottom: 20vw;

  .top {
    height: 10.6667vw;
    padding: 2.6667vw 5.3333vw 2.6667vw 5.3333vw;
    display: flex;
    font-size: 3.4667vw;
    justify-content: space-between;
    line-height: 3.0667vw;
  }
  .top1 {
    margin-top: 3.6667vw;
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 5.8667vw;
      color: #333333;
    }
    .icon {
      text-align: center;
      margin-top: 2vw;
    }
    .myConcent {
      margin: 5.3333vw 8vw 4vw 10vw;
      text-align: center;
    }
    .concentNum {
      font-weight: bold;
    }
  }
  .equityBox {
    .equityCent {
      width: 92vw;
      height: 46.6667vw;
      background: #ffffff;
      box-shadow: 0px 2px 8px 1px rgba(185, 185, 185, 0.31);
      border-radius: 10px;
      margin: auto;
      padding: 4vw;
      margin-top: 2.6667vw;
      .equityTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .equityImg {
          display: block;
          width: 7.2vw;
          height: 8.5333vw;
          margin-right: 2.4vw;
        }
        .equityTopTitle {
          font-size: 4.2667vw;
          color: #333333;
        }
      }
      .equitybot {
        margin-top: 2.6667vw;
      }
    }
  }
  .interestsBox {
    width: 88vw !important;
    height: 46.6667vw;
    background: #ffffff;
    box-shadow: 0px 2px 8px 1px rgba(185, 185, 185, 0.31);
    border-radius: 10px;
    padding: 4vw;
    background-image: url("../assets/yinhao2.png");
    background-repeat: no-repeat;
    background-position: 70vw 4vw;
    background-size: 8vw 6.1333vw;
    margin: 2.6667vw auto;
    .intrertsTitle {
      font-size: 4.2667vw;
      text-align: left;
      font-weight: bold;
    }
  }
  .caseBox {
    width: 92vw !important;
    height: 130vw;
    background: #ffffff;
    box-shadow: 0px 2px 10px 1px rgba(185, 185, 185, 0.29);
    border-radius: 10px;
    margin: auto;
    margin-bottom: 2.6667vw;
    .caseTop {
      height: 20.1333vw;
      background-image: url("../assets/beijing12.png");
      background-size: 92vw 20.1333vw;
      border-radius: 10px 10px 0px 0px;
      padding: 6.6667vw 0 0 5.3333vw;
      display: flex;
      img {
        width: 7.4667vw;
        height: 7.4667vw;
      }
      .classContent {
        font-size: 4vw;
      }
      .caseTopimage {
        display: flex;
        .img1 {
          margin-left: 1.3333vw;
        }
        .img2 {
          margin-left: 0.8vw;
        }
      }
    }
    .caseMiddle {
      padding: 5.3333vw 4vw;
      color: #4a4a4a;
      text-align: left;
      line-height: 5.6vw;
      height: 80vw;
      /deep/ span {
        color: #0a49ff;
        font-weight: bold;
      }
    }
    .caseBottom {
      display: flex;
      justify-content: space-around;
      margin-bottom: 5.3333vw;
      text-align: left;
      span {
        font-weight: bold;
        font-size: 5.3333vw;
      }
    }
  }
  .trendBox {
    width: 92vw !important;
    height: 62.6667vw;
    margin: auto;
    padding: 4vw 0 0 5.3333vw;
    margin-top: 2.6667vw;
    .trendTitle {
      font-size: 4vw;
      color: #ffffff;
      font-weight: bold;
    }
    .trendConcent {
      width: 88vw;
      height: 51.3333vw;
      background: #ffffff;
      border-radius: 10px;
      margin-left: -5.3333vw;
      margin-top: 4vw;
      padding: 2.3333vw;
    }
  }
  .trendBox:nth-child(1) {
    background-image: url("../assets/beijing7.png");
    background-size: 92vw 62.6667vw;
  }
  .trendBox:nth-child(2) {
    background-image: url("../assets/beijing8.png");
    background-size: 92vw 62.6667vw;
  }
  .trendBox:nth-child(3) {
    background-image: url("../assets/beijing9.png");
    background-size: 92vw 62.6667vw;
  }
  .trendBox:nth-child(4) {
    background-image: url("../assets/beijing10.png");
    background-size: 92vw 62.6667vw;
  }
  .definitionBox {
    display: flex;
    justify-content: space-around;
    padding: 5.3333vw 4vw 4vw 4vw;
    align-items: center;
    img {
      width: 31.2vw;
      height: 18vw;
    }
  }
  .registerBox {
    height: 113.3333vw;
    background-image: url("../assets/beijing11.png");
    background-size: 100vw 113.3333vw;
    .registerTitle {
      color: #ffffff;
      font-size: 5.8667vw;
      text-align: center;
      padding-top: 5.3333vw;
    }
    .registerCentent {
      padding: 5.3333vw 4vw 0 4vw;
      width: 50%;
      text-align: center;
      padding-bottom: 10.3333vw;
      img {
        width: 10vw;
        height: 10vw;
      }
      .registercenTit {
        color: #ffffff;
        font-size: 4vw;
        margin-top: 2.6667vw;
      }
      .registerCen {
        color: #ffffff;
        margin-top: 2.6667vw;
      }
    }
  }
  .authentication {
    width: 33.3333vw;
    height: 10vw;
    background: #0a49ff;
    text-align: center;
    color: #ffffff;
    font-size: 4vw;
    margin: 0 auto;
    line-height: 10vw;
    margin-top: 4vw;
    border-radius: 10px;
  }
  .iPhonetitle {
    margin: 0 auto;
    text-align: center;
    height: 30vw;
    .title {
      color: #333333;
      font-size: 3.7333vw;
      margin-bottom: 2.6667vw;
    }
  }
  .imgIcon {
    margin-left: 2.6667vw;
  }
}
</style>
